/* You can add global styles to this file, and also import other style files */
@import "~leaflet/dist/leaflet.css";
//@import "~ngx-bar-rating/themes/br-stars-theme";
@import "node_modules/ngx-bar-rating/themes/br-stars-theme";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/* Overrides a style class within 'mat-tab-group' to enable the height of the tab
content to be pushed down the DOM hierarchy (needed for Flexbox to work).*/
mat-tab-group .mat-tab-body-wrapper {
  display: flex;
  flex: auto;
  overflow: hidden;
  position: relative;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
}
